import { createEffect } from "effector";
import { clearLoginState, logoutFx } from "entities/login/model/tLoginFormModel";
import { fetchUser } from "shared/api/userApi";
import { WAVEUM_LOGIN } from "shared/constants/generic";
import { getLangPrefix } from "shared/helpers/genericHelper";

export const updateUserInfoFx = createEffect(async () => {

})

export const getUserFx = createEffect(async () => {
    try {
        const user = await fetchUser();
        if (user?.status) {
            if (user.status === 401 && user?.error === "Unauthorized") {
                logoutFx()
            }
        }
        return user;
    } catch (err: unknown) {
        console.log(err, 'error user effect')
    }

});
