import { createStore, createEffect, createEvent } from 'effector';
import { WAVEUM_LOGIN } from 'shared/constants/generic';
import { eraseCookie, getLangPrefix } from 'shared/helpers/genericHelper';
import { sessionStorageSync } from 'shared/helpers/storageHelper';

// Interface for LoginState
interface LoginState {
	error: null | string;
	loginData: any;
	isAuthenticated: boolean;
}

export const $loginState = createStore<LoginState>({
	error: null,
	loginData: null,
	isAuthenticated: Boolean(sessionStorageSync.privateToken),
});
export const clearLoginState = createEvent()
export const loginFx = createEffect(async (privateToken: string) => {
	sessionStorageSync.privateToken = privateToken;
	console.log('worked')
	eraseCookie('privateToken');
});

$loginState
	.on(loginFx, (state) => ({ ...state, error: null, loginData: null }))
	.on(loginFx.done, (state, { result }) => ({
		...state,
		error: null,
		loginData: result,
		isAuthenticated: true,
	}))
	.on(loginFx.fail, (state, { error }) => ({
		...state,
		error: error.message,
		loginData: null,
		isAuthenticated: false,
	})).reset(clearLoginState)

export const logoutFx = createEffect(() => {
	sessionStorage.clear()
	clearLoginState()
	localStorage.clear()
	window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
})