import i18next from 'i18next'
import React, { FC, useEffect } from 'react'
import { toDateFormat } from 'shared/helpers/genericHelper'
import { localStorageSync } from 'shared/helpers/storageHelper'
import MenuProfile from 'Widgets/Headar/ui/MenuProfile'
interface IProfileInfo {
  user: any
}
const ProfileInfo: FC<IProfileInfo> = ({ user }) => {


  useEffect(() => {
    if (user && user?.storeLanguage) {
      localStorage.setItem("language", user.storeLanguage)

    }
  }, [user])
  return (
    <div className='flex items-center'>
      <div className='font-semibold text-[16px] tracking-wider text-[orange] ml-3'>{toDateFormat(+new Date(), true)?.replace(",", "")} </div>
      <div className='user-info font-semibold text-[16px] tracking-wider ml-2 uppercase' style={{ color: "white" }}> | {user?.firstName}
        {user?.lastName}  | {user?.terminalCode} | {user?.storeCountry} | {user?.brandApp} | {user?.storeBrandId} </div>
      <MenuProfile />
    </div>
  )
}

export default ProfileInfo