import { localStorageSync } from "shared/helpers/storageHelper";

export const BRAND_ID = 21;
export const LANGUAGE = 'it_IT';
export const LANGUAGE_SHORT = localStorageSync.language;
export const WAVEUM_LOGIN = process.env.REACT_APP_WAVEUM_LOGIN;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ANALYTIC_URL = process.env.REACT_APP_ANALYTIC_URL;
export const enum Currency {
  EUR = "EUR",
  USD = "USD",
  CHF = "CHF"
}

export const enum LogisticStatuses {
  VAL = 'VAL',
  COM = 'COM',
  PRO = 'PRO',
  PEN = 'PEN',
  CLO = 'CLO',
  WAI = 'WAI',
  DIS = 'DIS',
  SHI = 'SHI',
  REA = 'REA',
  PRE = 'PRE',
  PIC = 'PIC',
  CAN = 'CAN',
  WIT = 'WIT',
  STO = 'STO',
  DEP = 'DEP',
  PAI = 'PAI',
  REC = 'REC',
  DIFF = 'DIFF',
  FAI = 'FAI',
  CHE = 'CHE'
};

export const enum CouponsType {
  PERCENT = "PERCENT",
  FIXED = "FIXED"
}

export const enum AdminManage {
  USERS = "USERS",
  STORES = "STORES",
  AGENTS = "AGENTS",
  WHOLESALERS = "WHOLESALERS",
  PARTNERS = "PARTNERS",
  UNREGISTERED = "UNREGISTERED"
}
